import { v4 as uuidv4 } from "uuid";

function chillHop() {
	


	return [
	
		{
			name: "So Different",
			cover:"https://storage.googleapis.com/nicoroc_audio/!%202%20nicoroc%20test.png",
			artist: "Nicoroc",
			album: "single",
			audio: "https://storage.googleapis.com/nicoroc_audio/So%20Different_MSTR_4.wav",
			color: ["#000000", "#7E3517"],
			id: uuidv4(),
			active: false,
		},
		{
			name: "Nightrider",
			cover:"https://storage.googleapis.com/nicoroc_audio/Attachment_1612947415.jpeg",
			artist: "Nicoroc",
			album: "single",
			audio: "https://storage.googleapis.com/nicoroc_audio/nico%20night%20rider%20zmix-feb3-master2.mp3",
			color: ["#000000", "#7E3517"],
			id: uuidv4(),
			active: false,
		},
		{
			name: "Take Back My Life",
			cover:"https://f4.bcbits.com/img/a4097316864_10.jpg",
			artist: "Nicoroc",
			album: "V R I L",
			audio: "https://storage.googleapis.com/nicoroc_audio/TakeBackMyLife-1209ZMASTER.mp3",
			color: ["#000000", "#7E3517"],
			id: uuidv4(),
			active: false,
		},
		{
			name: "Courier",
			cover:"https://f4.bcbits.com/img/a4097316864_10.jpg",
			artist: "Nicoroc",
			album: "V R I L",
			audio: "https://storage.googleapis.com/nicoroc_audio/Courier%20Nico%20-1125ZMASTER.mp3",
			color: ["#000000", "#7E3517"],
			id: uuidv4(),
			active: false,
		},
		{
			name: "Buried Alive",
			cover:"https://f4.bcbits.com/img/a4097316864_10.jpg",
			artist: "Nicoroc",
			album: "V R I L",
			audio: "https://storage.googleapis.com/nicoroc_audio/BurriedAlive%20-%201119ZMASTER%20.mp3",
			color: ["#000000", "#7E3517"],
			id: uuidv4(),
			active: false,
		},
		{
			name: "Imperium",
			cover:"https://f4.bcbits.com/img/a4097316864_10.jpg",
			artist: "Nicoroc",
			album: "V R I L",
			audio: "https://storage.googleapis.com/nicoroc_audio/Imperium%20Nico%20-1125ZMASTER.wav",
			color: ["#000000", "#7E3517"],
			id: uuidv4(),
			active: false,
		},
		{
			name: "Revival",
			cover:"https://f4.bcbits.com/img/a4097316864_10.jpg",
			artist: "Nicoroc",
			album: "V R I L",
			audio: "https://storage.googleapis.com/nicoroc_audio/Revival-1209ZMASTER.wav",
			color: ["#000000", "#7E3517"],
			id: uuidv4(),
			active: false,
		},
		{
			name: "Rope",
			cover:"https://f4.bcbits.com/img/a4097316864_10.jpg",
			artist: "Nicoroc",
			album: "V R I L",
			audio: "https://storage.googleapis.com/nicoroc_audio/Rope%20-1220ZMASTER%20(1).wav",
			color: ["#000000", "#7E3517"],
			id: uuidv4(),
			active: false,
		},
		{
			name: "Never Afraid",
			cover:"https://f4.bcbits.com/img/a4097316864_10.jpg",
			artist: "Nicoroc",
			album: "V R I L",
			audio: "https://storage.googleapis.com/nicoroc_audio/NeverAfraid-1209ZMASTER%20(1).wav",
			color: ["#000000", "#7E3517"],
			id: uuidv4(),
			active: false,
		},
		{
			name: "NME",
			cover:"https://f4.bcbits.com/img/a4097316864_10.jpg",
			artist: "Nicoroc",
			album: "V R I L",
			audio: "https://storage.googleapis.com/nicoroc_audio/Nme-1206ZMASTER.mp3",
			color: ["#000000", "#7E3517"],
			id: uuidv4(),
			active: false,
		},
		{
			name: "Passing My Life By",
			cover:"https://f4.bcbits.com/img/a4097316864_10.jpg",
			artist: "Nicoroc",
			album: "V R I L",
			audio: "https://storage.googleapis.com/nicoroc_audio/PassingMyLifeBy-1206ZMASTER.wav",
			color: "#6E0202",
			id: uuidv4(),
			active: false,
		},
		{
			name: "Been Up",
			cover:"https://f4.bcbits.com/img/a4097316864_10.jpg",
			artist: "Nicoroc",
			album: "V R I L",
			audio: "https://storage.googleapis.com/nicoroc_audio/PunkBeenUp%20-1220ZMASTER.wav",
			color: ["#000000", "#7E3517"],
			id: uuidv4(),
			active: false,
		},
		{
			name: "Malice",
			cover:"https://f4.bcbits.com/img/a4097316864_10.jpg",
			artist: "Nicoroc",
			album: "V R I L",
			audio: "https://storage.googleapis.com/nicoroc_audio/Malice%20Nico%20-1125ZMASTER.mp3",
			color: ["#000000", "#7E3517"],
			id: uuidv4(),
			active: true,
		},
		
		
		//ADD MORE HERE
	];
}

export default chillHop;