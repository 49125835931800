
import React, { useState, useRef } from "react";
import styled from "styled-components";
import "./App.css";
import './index.css';
// Import components

import Music from './components/Music'
import { ToastContainer } from "react-toastify";




const App = () => {
	

	return (
		
	<Music />
	);

	
};

export default App


